<template>
  <div id="myChart" :style="{width:'800px',height:'600px'}"></div>
</template>

<script>
import {queryFruitPieAPI} from '@/request/api.js'
export default {
  name: "myPie",
  mounted() {
    let _this = this;
    queryFruitPieAPI().then(function(response){
      console.log(response)
      // 基于准备好的dom,初始化E charts 实例
      let myChart = _this.$echarts.init(document.getElementById("myChart"));
      let myChartOption = {
        backgroundColor: '#2c343c',
        title: {
          text: '水果销量饼状图统计',
          left: 'center',
          top: 20,
          textStyle: {
            color: '#ccc'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1]
          }
        },
        series: [
          {
            name: '水果统计',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            //data: response.data
            data: [ {
              "value": 6051,
              "name": "2022-04-02",
              "itemStyle": {
                "color": "#a90000"
              }
            },
              {
                "value": 13086,
                "name": "2022-04-05",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 19660,
                "name": "2022-04-07",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 20398,
                "name": "2022-04-08",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 22609,
                "name": "2022-04-09",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 23937,
                "name": "2022-04-10",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 15173,
                "name": "2022-04-11",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 22348,
                "name": "2022-04-12",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 25141,
                "name": "2022-04-13",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 25146,
                "name": "2022-04-14",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 19872,
                "name": "2022-04-15",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 19923,
                "name": "2022-04-16",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 21582,
                "name": "2022-04-17",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 19831,
                "name": "2022-04-18",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 17332,
                "name": "2022-04-19",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 15861,
                "name": "2022-04-20",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 15861,
                "name": "2022-04-21",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 15698,
                "name": "2022-04-22",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 20634,
                "name": "2022-04-23",
                "itemStyle": {
                  "color": "#a90000"
                }
              },
              {
                "value": 19657,
                "name": "2022-04-24",
                "itemStyle": {
                  "color": "#a90000"
                }
              }]
            .sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: 'radius',
            label: {
              color: 'rgba(255, 255, 255, 0.3)'
            },
            labelLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.3)'
              },
              smooth: 0.2,
              length: 10,
              length2: 20
            },
            itemStyle: {
              color: '#c23531',
              shadowBlur: 200,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
              return Math.random() * 200;
            }
          }
        ]
      };
      // 绘制图表
      myChart.setOption(myChartOption);


    });

  }
}
</script>

<style scoped>
#myChart {
  width: 50%;
  height: 600px;
  text-align: center;
  margin:0 auto;
}
</style>
