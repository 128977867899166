<template>
  <div id="app">
    <el-container>
      <el-header>
        <!--导航栏-->
        <div id="nav">
          <router-link to="/" class="nav_link">首页</router-link>
          <!--|-->
          <!--<router-link to="/baiduHotNews" class="nav_link">热搜</router-link>
          |-->
          <!--<router-link to="/table" class="nav_link">数据管理</router-link>-->
          <!--|
          <router-link to="/pie" class="nav_link">饼图</router-link>
          |
          <router-link to="/bar" class="nav_link">柱状图</router-link>-->
          <!--｜-->
          <!--<router-link to="/musicManager" class="nav_link">音乐管理</router-link>
          |
                    <router-link to="/yinyuetai" class="nav_link">音悦台</router-link>-->

          <!--<router-link to="/today" class="nav_link">今天</router-link>-->
          <!--｜-->
          <!-- <a href="http://wanglichao.xyz:8081/">上传图片</a>
          ｜ -->
          <!--<a href="#" @click="openToBlock()" class="nav_link">去博客页面</a>-->

          <div style="display:inline;float:right">
            欢迎您: 用户   退出登录

        </div>
        </div>
        <!-- 用户信息 -->

      </el-header>

      <el-main>
        <div id="routerView">
          <router-view />
        </div>
      </el-main>

      <el-footer>
        <app-footer v-if="footer_show"></app-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import footer from "./views/footer.vue";

export default {
  name: "app",
  components: {
    HelloWorld,
    "app-footer": footer,
  },
  data() {
    return {
      footer_show: true,
    };
  },
  methods: {
    //是否显示头部
    header: function (bool) {
      this.header_show = bool;
    },
    //是否显示底部
    footer: function (bool) {
      this.footer_show = bool;
    },
    openToBlock: function(){
      window.open("https://www.cnblogs.com/wanglichaoya/");
    }
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

#routerView {
  /*width: 1000px;*/
  width: 100%;
  height: 700px;
  text-align: center;
  margin-top: -10px;
  /*margin-left: 245px;
  margin-top: 30px;*/
}
.nav_link{
  text-decoration:none;
  color:blueviolet;
  font-size: 20px;
}
</style>
