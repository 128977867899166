<template>
  <div id="main">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!--百度热搜-->
      <el-tab-pane label="百度热搜" name="first">
        <!--百度热搜的滚动通知-->
        <div class="bs-sysMsg" v-if="tableData.length > 0">
          <i class="el-alert__icon el-icon-warning"></i>
          <div class="msg__content">
            <el-carousel
              height="20px"
              direction="vertical"
              indicator-position="none"
              :autoplay="true"
            >
              <el-carousel-item v-for="item in tableData" :key="item.id">
                <a
                  href="#"
                  @click="baiduHotDetail(item.hotDetailHref)"
                  class="item"
                  >{{ item.title }}</a
                >
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!--  百度table       -->
        <div id="el_table_baidu">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
              <el-input
                placeholder="请输入百度热搜关键词"
                v-model="baiduHotNewsPo.keyWord"
                clearable
              />
            </el-form-item>

            <el-form-item>
              <el-select
                v-model="baiduHotNewsPo.hotType"
                clearable
                placeholder="请选择热搜类型"
              >
                <el-option
                  v-for="item in hotTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="baiduHotNewsPo.baiduHotDateTime"
                align="right"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="search">刷新百度热搜</el-button>
            </el-form-item>
          </el-form>

          <el-table
            :row-style="{ height: '45px' }"
            :cell-style="{ padding: '0px' }"
            v-loading="baidu_loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="
              tableData.slice(
                (bd_currentPage - 1) * bd_pageSize,
                bd_currentPage * bd_pageSize
              )
            "
            style="width: 100%; text-align: center"
            :row-class-name="tableRowClassName"
            :default-sort="{ prop: 'name', order: 'descending' }"
            border
          >
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="imgHref" label="热搜图片" width="100">
              <template slot-scope="scope">
                <el-popover placement="top-start" trigger="hover">
                  <!--trigger属性值：hover、click、focus 和 manual-->
                  <a
                    :href="scope.row.imgHref"
                    target="_blank"
                    title="查看最大化图片"
                  >
                    <img
                      :src="scope.row.imgHref"
                      style="width: 300px; height: 300px"
                    />
                  </a>
                  <img
                    slot="reference"
                    :src="scope.row.imgHref"
                    style="width: 50px; height: 50px; cursor: pointer"
                  />
                </el-popover>
              </template>
            </el-table-column>

            <!-- <el-table-column
              prop="title"
              label="热搜标题"
              width="300"
            ></el-table-column> -->
            <el-table-column prop="title" label="热搜标题" width="300">
              <template slot-scope="scope">
                <span v-html="showHighKeyWord(scope.row.title,baiduHotNewsPo.keyWord)"></span>
              </template>
            </el-table-column>

            <el-table-column
              prop="hotDesc"
              label="热搜摘要"
              width="800"
            ></el-table-column>
            <el-table-column prop="hotType" label="热搜状态" width="80">
              <template scope="scope">
                <span
                  v-if="scope.row.hotType == '新'"
                  style="background-color: #ff3852; color: #f7ebf9ff"
                  >{{ scope.row.hotType }}</span
                >
                <span
                  v-else-if="scope.row.hotType == '热'"
                  style="background-color: #ff9406; color: #f7ebf9ff"
                  >{{ scope.row.hotType }}</span
                >
                <span
                  v-else-if="scope.row.hotType == '沸'"
                  style="background-color: #f86400; color: #f7ebf9ff"
                  >{{ scope.row.hotType }}</span
                >
                <span
                  v-else-if="scope.row.hotType == '爆'"
                  style="background-color: #f80404ff; color: #f7ebf9ff"
                  >{{ scope.row.hotType }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="dateTime"
              label="更新时间"
              width="160"
            ></el-table-column>

            <el-table-column label="操作" width="90">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="baiduHotDetail(scope.row.hotDetailHref)"
                  >查看详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页器 -->
          <div class="block">
            <el-pagination
              @size-change="bd_handleSizeChange"
              @current-change="bd_handleCurrentChange"
              background
              :current-page="bd_currentPage"
              :page-sizes="[5, 8, 10, 15, 20, 30, 40]"
              :page-size="bd_pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="bd_totalSize"
            ></el-pagination>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="微博热搜" name="second">
        <!--微博热搜的滚动通知-->
        <div class="bs-sysMsg" v-if="weiBoHotTableData.length > 0">
          <i class="el-alert__icon el-icon-warning"></i>
          <div class="msg__content">
            <el-carousel
              height="20px"
              direction="vertical"
              indicator-position="none"
              :autoplay="true"
            >
              <el-carousel-item
                v-for="item in weiBoHotTableData"
                :key="item.id"
              >
                <a href="#" @click="baiduHotDetail(item.word)" class="item">{{
                  item.word
                }}</a>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <div id="el_table_weibo">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
              <el-input
                placeholder="请输入微博热搜关键词"
                v-model="weiBoHotNewsPo.keyWord"
                clearable
              />
            </el-form-item>

            <el-form-item>
              <el-select
                v-model="weiBoHotNewsPo.hotType"
                clearable
                placeholder="请选择热搜类型"
              >
                <el-option
                  v-for="item in wb_hotTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="weiBoHotNewsPo.weiBoHotDateTime"
                align="right"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="searchWeiBo"
                >刷新微博热搜
              </el-button>
            </el-form-item>
          </el-form>
          <!--微博table-->
          <el-table
            v-loading="weiBo_loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="
              weiBoHotTableData.slice(
                (wb_currentPage - 1) * wb_pageSize,
                wb_currentPage * wb_pageSize
              )
            "
            style="width: 100%; text-align: center"
            :row-class-name="tableRowClassName"
            :default-sort="{ prop: 'name', order: 'descending' }"
            border
          >
            <el-table-column type="index" width="50"></el-table-column>

            <el-table-column
              prop="word"
              label="热搜标题"
              width="500"
            >
            <template slot-scope="scope">
                <span v-html="showHighKeyWord(scope.row.word,weiBoHotNewsPo.keyWord)"></span>
              </template>
            </el-table-column>
            <el-table-column prop="icon_desc" label="热搜状态" width="100">
              <template scope="scope">
                <span
                  v-if="scope.row.icon_desc === '新'"
                  style="background-color: #ff3852; color: #f7ebf9ff"
                  >{{ scope.row.icon_desc }}</span
                >
                <span
                  v-else-if="scope.row.icon_desc === '热'"
                  style="background-color: #ff9406; color: #f7ebf9ff"
                  >{{ scope.row.icon_desc }}</span
                >
                <span
                  v-else-if="scope.row.icon_desc === '沸'"
                  style="background-color: #f86400; color: #f7ebf9ff"
                  >{{ scope.row.icon_desc }}</span
                >
                <span
                  v-else-if="scope.row.icon_desc === '爆'"
                  style="background-color: #f80404ff; color: #f7ebf9ff"
                  >{{ scope.row.icon_desc }}</span
                >
                <span
                  v-else-if="scope.row.icon_desc === '商'"
                  style="background-color: #00b7ee; color: #f7ebf9ff"
                  >{{ scope.row.icon_desc }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="category"
              label="热搜类型  "
              width="200"
            ></el-table-column>

            <el-table-column
              prop="num"
              label="热度"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="dateTime"
              label="更新时间"
              width="200"
            ></el-table-column>

            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="weiBoHotDetail(scope.row.word)"
                  >查看详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 微博分页器 -->
          <div class="block">
            <el-pagination
              @size-change="wb_handleSizeChange"
              @current-change="wb_handleCurrentChange"
              background
              :current-page="wb_currentPage"
              :page-sizes="[5, 8, 10, 15, 20, 30, 40]"
              :page-size="wb_pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="wb_TotalSize"
            ></el-pagination>
          </div>
        </div>
      </el-tab-pane>

      <!--知乎热搜-->
      <el-tab-pane label="知乎热搜" name="three">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-date-picker
              v-model="zhiHuhotPo.zhihuHotDateTime"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="searchZhiHu"
              >刷新知乎热搜
            </el-button>
          </el-form-item>
        </el-form>
        <el-table
          v-loading="zhihu_loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="
            zhihuTableData.slice(
              (zh_currentPage - 1) * zh_pageSize,
              zh_currentPage * zh_pageSize
            )
          "
          style="width: 100%"
          :row-class-name="tableRowClassName"
          :default-sort="{ prop: 'name', order: 'descending' }"
        >
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column
            prop="title"
            label="标题"
            width="700"
          ></el-table-column>
          <!--          <el-table-column prop="excerpt" label="摘要" width="600"></el-table-column>-->
          <el-table-column
            prop="detailText"
            label="热度"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="dateTime"
            label="更新时间"
            width="200"
          ></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="zhiHuHotDetail(scope.row.id)"
                >查看详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination
            @size-change="zh_handleSizeChange"
            @current-change="zh_handleCurrentChange"
            background
            :current-page="zh_currentPage"
            :page-sizes="[8, 10, 15, 20, 30, 40]"
            :page-size="zh_pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="zh_TotalSize"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getBaiduHotNews, getWeiBoHot, getZhiHuHot } from "../request/api";

export default {
  name: "baiduHotNews",
  data() {
    return {
      //默认选择哪个tab页面
      activeName: "first",
      //百度热table数据
      tableData: [],
      bd_currentPage: 1,
      // 默认显示6条
      bd_pageSize: 5,
      // 默认总条数为0 这个无所谓，前端分页可以计算数组的length
      bd_totalSize: 0,
      baidu_loading: false,

      // 默认第1页
      // 知乎热搜table数据
      zhihuTableData: [],
      zh_currentPage: 1,
      // 默认显示6条
      zh_pageSize: 5,
      zh_TotalSize: 0,
      zhihu_loading: false,

      // 微博热搜table数据
      weiBoHotTableData: [],
      wb_currentPage: 1,
      // 默认显示6条
      wb_pageSize: 5,
      wb_TotalSize: 0,
      weiBo_loading: false,

      baiduHotNewsPo: {
        // 热搜类型：
        hotType: "",
        baiduHotDateTime: "",
        keyWord: "",
      },
      // 知乎热搜查询参数
      zhiHuhotPo: {
        zhihuHotDateTime: "",
      },
      // 微博热搜查询参数
      weiBoHotNewsPo: {
        // 热搜类型
        hotType: "",
        weiBoHotDateTime: "",
        keyWord: "",
      },

      // 热搜类型
      hotType: "",
      // 百度热搜下拉选择 热搜类型
      hotTypeOptions: [
        {
          value: "新",
          label: "新",
        },
        {
          value: "热",
          label: "热",
        },
        {
          value: "沸",
          label: "沸",
        },
        {
          value: "爆",
          label: "爆",
        },
      ],

      // 微博热搜查询下拉条件
      wb_hotTypeOptions: [
        {
          value: "新",
          label: "新",
        },
        {
          value: "热",
          label: "热",
        },
        {
          value: "沸",
          label: "沸",
        },
        {
          value: "爆",
          label: "爆",
        },
        {
          value: "商",
          label: "商",
        },
      ],
      // 热搜日期
      baiduHotDateTime: "",
      // 日期筛选框
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  methods: {
    // 百度分页
    // 每页显示条数
    bd_handleSizeChange(pageSize) {
      this.bd_pageSize = pageSize;
    },
    // 当前页
    bd_handleCurrentChange(currentPage) {
      this.bd_currentPage = currentPage;
    },
    // 微博分页
    // 每页显示条数
    wb_handleSizeChange(pageSize) {
      this.wb_pageSize = pageSize;
    },
    // 当前页
    wb_handleCurrentChange(currentPage) {
      this.wb_currentPage = currentPage;
    },

    // 知乎分页
    // 每页显示条数
    zh_handleSizeChange(pageSize) {
      this.zh_pageSize = pageSize;
    },
    // 当前页
    zh_handleCurrentChange(currentPage) {
      this.zh_currentPage = currentPage;
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else if (rowIndex % 3 == 0) {
        return "info-row";
      }
      return "success-row";
    },
    // 查询百度热搜
    search: function () {
      let that = this;
      that.tableData = [];
      // 查询百度热搜数据
      let hotType =
        this.baiduHotNewsPo.hotType == "" ? "all" : this.baiduHotNewsPo.hotType;
      this.baiduHotNewsPo.hotType = hotType;
      this.baidu_loading = true;
      getBaiduHotNews(this.baiduHotNewsPo).then(function (response) {
        that.tableData = response.data.data;
        that.bd_totalSize = that.tableData.length;
        that.baidu_loading = false;
        if (that.tableData.length > 0) {
          that.$message({
            // 显示1s关闭弹窗
            duration: 1000,
            message: "百度热搜刷新成功！",
            type: "success",
          });
        }
      });
    },
    searchZhiHu: function () {
      let that = this;
      // 查询知乎热搜
      this.zhihu_loading = true;
      getZhiHuHot(this.zhiHuhotPo).then(function (response) {
        that.zhihuTableData = response.data.data;
        that.zh_TotalSize = that.zhihuTableData.length;
        that.zhihu_loading = false;
        if (that.zhihuTableData.length > 0) {
          that.$message({
            // 显示1s 关闭弹窗
            duration: 1000,
            message: "知乎热搜刷新成功！",
            type: "success",
          });
        }
      });
    },
    handleClick(tab, event) {},
    searchWeiBo: function () {
      let that = this;
      // 先把数据清空
      that.weiBoHotTableData = [];
      // 查询百度热搜数据
      let hotType =
        this.weiBoHotNewsPo.hotType == "" ? "all" : this.weiBoHotNewsPo.hotType;
      this.weiBoHotNewsPo.hotType = hotType;
      this.weiBo_loading = true;
      // 查询微博热搜
      getWeiBoHot(this.weiBoHotNewsPo).then(function (response) {
        that.weiBoHotTableData = response.data.data;
        that.wb_TotalSize = that.weiBoHotTableData.length;
        that.weiBo_loading = false;
        if (that.weiBoHotTableData.length > 0) {
          that.$message({
            message: "微博热搜刷新成功！",
            duration: 1000,
            type: "success",
          });
        }
      });
    },
    baiduHotDetail: function (path) {
      debugger;
      window.open(path);
    },
    zhiHuHotDetail: function (id) {
      window.open("https://www.zhihu.com/question/" + id);
    },
    weiBoHotDetail: function (word) {
      let url = "https://s.weibo.com/weibo?q=" + word;
      window.open(url);
    },
    // 设置搜索的关键字高亮
    showHighKeyWord(val,k) {
      let keyWord = k;
      val = val + "";

      if (val.indexOf(keyWord) !== -1 && keyWord !== "") {
        return val.replace(
          keyWord,
          '<font color="red">' + keyWord + "</font>"
        );
      } else {
        return val;
      }
    },
  },
  created: function () {
    let aData = new Date();
    let month =
      aData.getMonth() + 1 < 10
        ? "0" + (aData.getMonth() + 1)
        : aData.getMonth() + 1;
    let day = aData.getDate() < 10 ? "0" + aData.getDate() : aData.getDate();
    let dateValue = aData.getFullYear() + "-" + month + "-" + day;

    const that = this;
    // 查询百度热搜数据
    this.baiduHotNewsPo.hotType = "all";
    this.baiduHotNewsPo.baiduHotDateTime = dateValue;
    // loading
    this.baidu_loading = true;
    // 查询百度热搜
    getBaiduHotNews(this.baiduHotNewsPo).then(function (response) {
      that.tableData = response.data.data;
      that.bd_totalSize = that.tableData.length;
      that.baidu_loading = false;
    });

    // 查询知乎热搜
    this.zhihu_loading = true;
    getZhiHuHot(this.zhiHuhotPo).then(function (response) {
      that.zhihuTableData = response.data.data;
      that.zh_TotalSize = that.zhihuTableData.length;
      that.zhihu_loading = false;
    });

    // 查询微博热搜
    this.weiBoHotNewsPo.hotType = "all";
    this.weiBoHotNewsPo.weiBoHotDateTime = dateValue;
    this.weiBo_loading = true;
    getWeiBoHot(this.weiBoHotNewsPo).then(function (response) {
      that.weiBoHotTableData = response.data.data;
      that.wb_TotalSize = that.weiBoHotTableData.length;
      that.weiBo_loading = false;
    });
  },
};
</script>

<style scoped>
#main {
  width: 100%;
  height: 600px;
  text-align: center;
  margin: 0 auto;
}

/**轮播图*/
/*轮翻消息*/
.bs-sysMsg {
  position: relative;
  display: flex;
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 10px;
  border-radius: 2px;
  color: #e6a23c;
  background-color: #fdf6ec;
  overflow: hidden;
  opacity: 1;
  align-items: center;
  transition: opacity 0.2s;
}

.bs-sysMsg .msg__content {
  display: table-cell;
  padding: 0 8px;
  width: 100%;
}

.bs-sysMsg .msg__content a.item {
  color: #e6a23c;
  font-size: 14px;
  opacity: 0.75;
}

.bs-sysMsg .msg__content a.item:hover {
  text-decoration: underline;
}
</style>
