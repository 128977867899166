import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Pie from '../views/Pie.vue'
import Table from '../views/Table.vue'
import Add from '../views/Add.vue'
import Edit from '../views/Edit.vue'

import IsShow from '../views/base/IsShow.vue'
import VBind from '../views/base/VBind.vue'
import musicManager from '../views/musicManager.vue'

import yinyuetai from '../views/yingyuetai.vue'
import musicAudio from "../views/musicAudio";

import today from '../views/Today';
import baiduHotNews from '../views/baiduHotNews';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/bar',
    name: 'Bar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Bar.vue')
    },
  },{
    path: '/pie',
    name: 'Pie',
    component: Pie
  },
  {
    path: '/table',
    name: 'Table',
    component: Table
  },
  {
    path: '/add',
    name: 'Add',
    component: Add
  },
  {
    path: '/base',
    name: 'IsShow',
    component: IsShow
  },
  {
    path: '/vbind',
    name: 'VBind',
    component: VBind
  },
  {
    path: '/edit',
    name: 'Edit',
    component: Edit
  },
  {
    path: '/musicManager',
    name: 'musicManager',
    component: musicManager
  },
  {
    path: '/yinyuetai',
    name: 'yinyuetai',
    component: yinyuetai
  },
  {
    path: '/musicAudio',
    name: 'musicAudio',
    component: musicAudio
  },
  {
    path: '/today',
    name:'today',
    component: today
  },
  {
    path:'/baiduHotNews',
    name: 'baiduHotNews',
    component: baiduHotNews
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
