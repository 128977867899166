<template>


  <div id="main">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-select v-model="stockType" placeholder="请选择股票类型">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="每次请求多少条数据">
        <el-select v-model="stockShallPo.type" placeholder="请选择调用第三方服务每次请求多少条数据">
          <el-option
              v-for="item in pageTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="请求第几页数据">
        <el-input v-model="stockShallPo.page" placeholder="请求第三方服务的第几页数据"></el-input>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" @click="search">查询
        </el-button>

      </el-form-item>
    </el-form>


    <!-- table数据展示 -->
    <el-table
        :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :default-sort="{prop: 'name', order: 'descending'}"
    >
      <el-table-column
          fixed
          prop="symbol"
          label="股票代码"
          sortable
      >
      </el-table-column>

      <el-table-column
          fixed
          prop="name"
          label="名称"
          sortable
      >
      </el-table-column>
      <el-table-column
          sortable

          prop="trade"
          label="最新价"
      >
      </el-table-column>
      <el-table-column
          sortable

          prop="pricechange"
          label="涨跌额"
      >
      </el-table-column>
      <el-table-column
          sortable

          prop="changepercent"
          label="涨跌幅"
      >
      </el-table-column>
      <el-table-column
          sortable

          prop="buy"
          label="买入"
      >
      </el-table-column>
      <el-table-column
          sortable

          prop="sell"
          label="卖出"
      >
      </el-table-column>
      <el-table-column

          sortable
          prop="settlement"
          label="昨收"
      >
      </el-table-column>
      <el-table-column
          sortable
          prop="open"
          label="今开"
      >
      </el-table-column>
      <el-table-column
          sortable
          prop="high"
          label="最高"
      >
      </el-table-column>
      <el-table-column
          sortable
          prop="low"
          label="最低"
      >
      </el-table-column>
      <el-table-column
          sortable
          prop="volume"
          label="成交量"
      >
      </el-table-column>
      <el-table-column
          sortable
          prop="amount"
          label="成交额"
      >
      </el-table-column>
      <el-table-column
          sortable
          prop="code"
          label="简码"
      >
      </el-table-column>
      <el-table-column
          sortable

          prop="ticktime"
          label="时间"
      >
      </el-table-column>
      <el-table-column

          prop="per"
          label="未说明"
      >
      </el-table-column>
      <el-table-column
          sortable

          prop="pb"
          label="市净率"
      >
      </el-table-column>
      <el-table-column
          sortable

          prop="mktcap"
          label="总市值(万)"
      >
      </el-table-column>
      <el-table-column
          sortable

          prop="nmc"
          label="流通值(万)"
      >
      </el-table-column>
      <el-table-column
          sortable

          prop="turnoverratio"
          label="换手率"
      >
      </el-table-column>

    </el-table>
    <!-- 分页器 -->
    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="currentPage"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import {getStockShall} from "../request/api";

export default {
  data() {
    return {
      currentPage: 1, // 默认第1页
      pageSize: 5, // 默认显示5条
      totalSize: 0, // 默认总条数为0 这个无所谓，前端分页可以计算数组的length
      // 拿到的数组
      tableData: [],
      stockShallPo: {
        // 股票类型：  a股/b股
        stock: '',
        //第几页,默认第1页
        page: 1,
        //每页返回条数,1(20条默认),2(40条),3(60条),4(80条)
        type: 2
      },
      today: {
        holiday: '',
        avoid: '',
        animalsYear: '',
        desc: '',
        weekday: '',
        suit: '',
        lunarYear: '',
        lunar: '',
        year_month: ''
      },
      // 下拉选择A股活着B股
      options: [{
        value: 'a',
        label: 'A股'
      }, {
        value: 'b',
        label: 'B股'
      }],
      stockType: '',
      pageTypeOptions: [
        {
          value: '1',
          label: '20条'
        }, {
          value: '2',
          label: '40条'
        }, {
          value: '3',
          label: '60条'
        }, {
          value: '4',
          label: '80条'
        }
      ]
    }
  },
  created() {
    const that = this;


  },
  methods: {
    // 初始页currentPage、初始每页数据数pageSize和数据data

    // 每页显示条数
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      console.log(this.pageSize) // 每页下拉显示数据
    },
    // 当前页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      console.log(this.currentPage)
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      } else if (rowIndex % 3 == 0) {
        return 'info-row';
      }
      return 'success-row';
    },
    // 查询
    search: function () {
      let that = this;
      // 查询沪股
      this.stockShallPo.stock = this.stockType;
      getStockShall(this.stockShallPo).then(function (response) {
        console.log(response)
        that.tableData = response.data.data.result.data;
        that.total = that.tableData.length;
      })
    }


  }
}
</script>
<style>
#main{
  width: 90%;
  height: 600px;
  text-align: center;
  margin:0 auto;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table .info-row {
  background: #f7ebf9;
}
</style>