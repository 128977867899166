// 将request.js 整体导入
import request from './request'

// 按需到处每个请求,也就是按需到处每个API

// 请求table 数据
export const GetTableAPI = () => request.get('fruitAdmin/fruit/list');

// 删除table 指定的id 的数据
export const DeleteTableAPI = (param) => request.post('fruitAdmin/fruit/deleteFruit', param);

// 新增水果
export const addFruitAPI = (param) => request.post('fruitAdmin/fruit/add', param);

// 修改水果的信息
export const UpdateTableAPI = (param) => request.put('fruitAdmin/fruit/update', param);

// 根据ID查询水果的信息
export const queryFruitByIdAPI = (param) => {
    const url = 'fruitAdmin/fruit/findById/' + param;
    return request.get(url);
};

// 查询水果柱状图 图
export const queryFruitBar = (param) => request.get('fruitAdmin/fruit/queryBar');
// 查询水果pie图
export const queryFruitPieAPI = (param) => request.get('fruitAdmin/fruit/queryPie');

// 根据歌曲名称查询歌曲信息
export const queryMusicByNameAPI = (param) => {
    const url = 'fruitAdmin/Test?musicName=' + param;
    return request.get(url);
};
// 获取歌单信息
export const getGeDan = () => request.get('fruitAdmin/getGeDan');

// 获取歌单歌曲列表
export const getGeDanList = (param) => {
    const url = 'fruitAdmin/getGeDanMusic?geDanUrl=' + param
    return request.get(url);
}

// 万年历接口，获取当天的详细信息
// export const getTodayDetail = (param) => {
//     const url = '/api/getTodayDetail/'+param.today;
//     return request.get(url);
// }

// 万年历接口
//export const getTodayDetail = (param) => request.post('/api/finance/stock/shall',param);

// 沪股列表接口
export const getStockShall = (param) => request.post('fruitAdmin/api/finance/stock/shall',param);

// 新闻热搜接口
// export const getBaiduHotNews = (param) =>{
//
//     const url = 'fruitAdmin/reSou/getBaiduHotNews/' + param.hotType;
//     return request.get(url);
// };
// 获取百度热搜新闻接口
export const getBaiduHotNews = (param) =>request.post('fruitAdmin/reSou/getBaiduHotNews',param);

export const getBaiduHotNewsByTotal = (param) => {
    const url = 'fruitAdmin/reSou/getBaiduHotNewsByTotal/' + param
    return request.get(url);
}
export const getWeiboHotNewsByTotal = (param) => {
    const url = 'fruitAdmin/reSou/getHistoryWeiBoHotNewsByTotal/' + param
    return request.get(url);
}



// 获取知乎热搜数据接口
export const getZhiHuHot = (param) => request.post('fruitAdmin/zhihu',param);
export const getWeiBoHot = (param) => request.post('fruitAdmin/reSou/weibo',param);

// 获取首页轮播图
export const getCarousel = () => request.get('fruitAdmin/getCarousel');

// 疫情
// 查询上海疫情数据
// 查询水果柱状图 图
export const queryYiQingBar = (param) => request.get('fruitAdmin/yiQin/queryShangHaiBar');
