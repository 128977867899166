<template>
  <div id="app">
    <!--
    v-if: 指令的作用是：根据表达式的真假，切换元素的状态，本质是通过操作dom元素来显示状态，会有性能的影响
          表达式为true，元素存在于dom树中，为false，从dom树中移除；
    v-show: 指令的作用是： 根据表达式的真假，切换元素的显示状态，dom元素一直存在于dom树中
    -->
    <input type="button" value="切换显示" @click="toggleIsShow"></input>
    <p v-if="isShow">黑马程序员</p>
    <p v-show="isShow">黑马程序员-v-show修饰</p>
    <p v-if="wendu>35">热死了</p>
  </div>
</template>

<script>
export default {
  name: "vue命令的学习",
  data() {
    return {
      isShow: false,
      wendu: 35
    }
  },
  methods: {
    toggleIsShow: function () {
      this.isShow = !this.isShow;
    }
  }
}
</script>

<style scoped>

</style>