// 这个文件是用来做拦截的

import axios from 'axios'
import { createLogger } from 'vuex';

// 请求地址
//const ip1 = '150.158.173.77';
//const ip1 = 'wanglichao.xyz';
const ip2 = 'localhost';
const ip4 = '192.168.1.3';
const ip3 = '192.168.83.129';
const localUrl1="http://"+ip2+":8181";
// 创建一个单例
const instance = axios.create({
    baseURL:localUrl1,
    timeout: 40000
})

// 拦截器 -请求的时候拦截
instance.interceptors.request.use(config=>{
    let token = localStorage.getItem("token");

    if(token){
        config.headers = {
            'aa-token':token
        }
    }
    return config;
},error=>{
    console.log("发生了错误,错误为:"+error);
    return Promise.reject(error);
});


// 拦截器 -相应的时候拦截
instance.interceptors.response.use(res=>{

    console.log("响应结果为:"+res);
    return res;
},error=>{
    return Promise.reject(error);
});

// 整体导出
export default instance;
