<template>
  <div id="main">
    <div>
     <div>
       <el-button  type="primary" @click="prePlay()">上一曲</el-button> ｜

       <span style="color: lightcoral" v-show="musicName !=''">{{musicName}}</span> ｜
       <el-button  type="primary" @click="nextPlay()">下一曲</el-button>
     </div>
      <audio id="audio2" :paused="audioPaused" :src="playUrl" controls ref="audioVo"></audio>
    </div>

    <div v-show="loading">
      正在查询中，请稍后。。。。
<!--      <img src="src/assets/075.gif">-->
      <img :src="defaultImg" />
    </div>
    <div v-show="musicListNameShow">
     <span style="color: tomato"> 歌单： {{musicListName}}</span>
    </div>
    <el-row :gutter="32" class="el-row" type="flex">
      <el-col :span="6" v-for="(item,index) in musicList" class="el-col">
        <div  @click="playMusic(item.musicUrl,index,item.musicName)">
          <el-card class="el-card" :key="index">
            <div slot="header" class="clearfix">
              <span>{{index+1}} </span>
              <span>{{ item.musicName }}</span>
            </div>
            <div>

              <div class="text item">
                <a href="#" style="display: none">{{ item.musicUrl }}</a>
              </div>
              <div class="text item">
                <img :src="item.musicImg" :alt="item.musicImg" :title="item.musicImg"
                     style="width:200px;height: 200px">
              </div>
              <!--              <div class="text item">-->
              <!--                <audio id="audio" :src="item.musicUrl" controls></audio>-->
              <!--              </div>-->

            </div>
          </el-card>
        </div>

      </el-col>


    </el-row>
    <el-divider>
      <div v-show="bottomMsgShow">
        <span >{{bottomMsg}}</span>
      </div>

    </el-divider>
<!--    <div v-show="bottomMsgShow">
      <span >{{bottomMsg}}</span>
    </div>-->

  </div>
</template>

<script>
import {getGeDanList} from "../request/api";
//import deImg  from '@/assets/075.gif'
export default {
  methods: {
    prePlay: function (){

    },
    playMusic: function (item, index,musicName) {

      let that1 = this;

      that1.musicName = musicName;
      this.playUrl = item;
      // 自动播放
      this.$refs.audioVo.autoplay = true;

      //控制音量
      this.$refs.audioVo.volume = 0.4;
      let that = this.$refs.audioVo;
      setInterval(function () {
        console.log(this);
        console.log("当前是否已经播放完毕" + that.ended)
        if (that.ended) {
          index = index+1;
          // 如果播放完了，就播放第一首歌曲
          if (index == that1.musicList.length - 1) {
            that1.playUrl = that1.musicList[0].musicUrl;
            that1.musicName = that1.musicList[0].musicName;
          } else {
            that1.playUrl = that1.musicList[index].musicUrl;
            that1.musicName = that1.musicList[index].musicName;
          }
          that1.$refs.audioVo.autoplay = true;
          console.log("开始播放 " + that1.playUrl)

        }
      }, 1000);
    }
  },
  // 钩子函数
  created: function () {
    this.geDanUrl = this.$route.params.param.geDanHref;
    if ("" == this.geDanUrl) {
      // this.reload();
      this.$router.push({
        path: '/yinyuetai',
        name: 'yinyuetai',

      })
    }
    // 查询歌曲
    const that = this;
    that.loading = true;
    this.bottomMsgShow=false;
    that.musicListName = this.$route.params.param.title;
    // 获取歌单下的列表
    getGeDanList(that.geDanUrl).then(function (response) {
      that.musicList = response.data;
      console.log(that.musicList)
      that.loading = false;
      that.musicListNameShow = true;
      that.bottomMsgShow=true;
    });
  },
  data() {
    return {
      geDanUrl: '',
      musicList: '',
      //音乐地址
      playUrl: '',
      audioPaused: false,
      // 当前播放的歌曲名称
      musicName:'',
      loading:false,
      musicListNameShow: false,
      // 歌单名称
      musicListName: '',
      //defaultImg:deImg
      defaultImg:require('../assets/075.gif'),
      bottomMsgShow:false,
      bottomMsg:'我是有底线的 >!< '

    };
  },
};
</script>

<style>

#main {
  width: 1200px;
}

.item {
  margin-bottom: 10px;
}

.text {
  width: 100%;
  font-size: 12px;
  font-family: "Microsoft YaHei";
  color: #909399;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.el-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
  /*transition: all .5s;*/
}

.el-card:hover {
  margin-top: -5px;
  border: 1px solid red
}

.el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap
}

.el-col {
  border-radius: 4px;
  align-items: stretch;
  margin-bottom: 40px;
}
</style>
