<template>
  <div id="main">
    <el-form ref="fruitRules" :model="fruit" :rules="rules" label-width="80px" class="demo-ruleForm"
             style="width: 600px">
      <el-form-item label="名称" prop="fruitName">
        <el-input v-model="fruit.fruitName"></el-input>
      </el-form-item>
      <el-form-item label="销量" prop="sale">
        <el-input v-model.number="fruit.sale"></el-input>
      </el-form-item>
      <!--      <el-form-item label="图片" prop="icon">-->
      <!--        <el-input v-model="fruit.icon"></el-input>-->
      <!--      </el-form-item>-->

      <el-upload
          class="upload-demo"
          drag
          action="http://150.158.173.77:8181/fruitAdmin/api/upload"
          name="userHeaderPicture"
          v-model="fruit.icon"
          :on-success="handleAvatarSuccess"
          multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
      </el-upload>
      <img :src="myImg" v-show="myImgShow" style="width:200px;height: 200px"/>

      <el-form-item>
        <el-button type="primary" @click="onSubmit('fruitRules')">立即创建</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>

import {UpdateTableAPI, queryFruitByIdAPI} from '@/request/api.js'

export default {
  name: "Add",
  data() {
    return {
      fruit: {
        fruitName: '',
        sale: '',
        icon: ''
      },
      rules: {
        fruitName: [
          {required: true, message: '请输入水果名称', trigger: 'blur'}
        ],
        sale: [
          {required: true, message: '请输入销量', trigger: 'blur'},
          {type: 'number', message: '销量必须为数字值'}
        ],
        icon: [
          {required: true, message: '请输入图片链接', trigger: 'blur'}
        ]
      },
      myImgShow: false,
      myImg:''
    }
  },
  mounted() {
    let _this = this;
    // 获取到当前修改数据的ID
    let id = this.$route.query.id;
    // 根据水果的ID查询水果的信息
    // this.$route.query.id 是跳转的时候带过来的参数
    queryFruitByIdAPI(id).then(function (response) {
      debugger
      _this.fruit = response.data;
      _this.myImg = response.data.icon;
      _this.myImgShow = true;
    });

  },
  methods: {
    handleAvatarSuccess(res, file) {
      debugger
      //this.imageUrl = URL.createObjectURL(file.raw);
      //重点  得到上传图片的名字
      this.fruit.icon = res.data.data;
      this.myImg = res.data.data;
      this.myImgShow = true;
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let _this = this
          UpdateTableAPI(this.fruit).then(function (response) {
            if (response.data) {
              _this.$router.push('/table')
            }
          });
        }
      });
    }
  }
}
</script>

<style scoped>
#main {
  width: 40%;
  height: 600px;
  text-align: center;
  margin: 0 auto;
}
</style>