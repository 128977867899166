<template>
  <div class="wrap" id="app-footer">
    <hr />
    <div id="footer">
      <div id="footer-beifan">
        <span style="color: #7d818a">© 2024</span> &nbsp;| &nbsp;
        王立朝提供技术支持 | &nbsp;
        <a target="_blank" href="https://beian.miit.gov.cn/" style="color: red;text-decoration:none;"
          >豫ICP备2022007662号-1
        </a>
        | &nbsp;
         <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41058102000279"
          style="color:#7d818a;text-decoration:none;"
          ><img :src="beian_police"/>

            豫公网安备 41058102000279号
          </a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "footer.vue",
  data() {
    return {
      beian_police:require('../assets/beian_police.png')
    };
  },
};
</script>

<style scoped>
/*#app-footer{*/
/*  background-color: #314158;*/
/*  height: 50px;*/

/*}*/
/*#footer-beifan{*/
/*  margin-bottom: 80px;*/
/*}*/
</style>
