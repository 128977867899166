import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

// 导入jquery 和 bootstrap
import 'jquery/dist/jquery.min.js'
// 导入Echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.prototype.$host = "/Users/wanglichao/Desktop/temp/image/"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
