<template>
  <div id="main">
    <el-table
      :data="
        tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      "
      border
      style="width: 100%"
    >
      <el-table-column fixed prop="id" label="id" width="80"> </el-table-column>
      <el-table-column
        fixed
        prop="updateTime"
        label="创建时间"
        align="center"
        width="200"

      >
      </el-table-column>
      <el-table-column prop="fruitName" label="水果名称" width="200">
      </el-table-column>
      <el-table-column prop="sale" label="销量" width="120"> </el-table-column>

      <el-table-column prop="icon" label="图片" width="120">
        <template slot-scope="scope">

          <el-popover placement="top-start" trigger="hover">
							<!--trigger属性值：hover、click、focus 和 manual-->
							<a :href="scope.row.icon" target="_blank" title="查看最大化图片">
                <img :src="scope.row.icon" style="width: 300px;height: 300px" />
              </a>
							<img slot="reference" :src="scope.row.icon"
              style="width: 50px;height: 50px; cursor:pointer" />
						</el-popover>

        </template>
      </el-table-column>

      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <!-- <el-button @click="addFruit()" type="text" size="small"
            >新增</el-button
          > -->
          <!--<el-button @click="delFruit(scope.row)" type="text" size="small"
            >删除</el-button
          >-->
          <!-- <el-button @click="findById(scope.row)" type="text" size="small"
            >修改</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
//按需导入
import { GetTableAPI, DeleteTableAPI } from "@/request/api.js";
import Qs from "qs";

export default {
  methods: {
    findById: function (object) {
      this.$router.push("/Edit?id=" + object.id);
    },
    //时间格式化
    dateFormat: function (row, column) {
      var t = new Date(row.createTime); //row 表示一行数据, updateTime 表示要格式化的字段名称
      return (
        t.getFullYear() +
        "-" +
        (t.getMonth() + 1) +
        "-" +
        t.getDate() +
        " " +
        t.getHours() +
        ":" +
        t.getMinutes() +
        ":" +
        t.getSeconds()
      );
    },
    addFruit() {
      this.$router.push("/add");
    },
    delFruit(row) {
      let _this = this;
      this.$confirm("是否确定要删除" + row.fruitName + "?", "删除数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(function () {
        let param = Qs.stringify({ id: row.id });
        DeleteTableAPI(param).then(function (response) {
          console.log(response);
          if (response.data) {
            // _this.$alert(row.fruitName + '删除成功！', '删除数据', {
            //   confirmButtonText: '确定',
            //   callback: action => {
            //     //跳转到/table
            //     location.reload()
            //   }
            // });
            _this.$message({
              message: row.fruitName + "删除成功！",
              type: "success",
            });
            location.reload();
          } else {
            _this.$message({
              message: row.fruitName + "删除成功！",
              type: "warning",
            });
          }
        });
      });
    },
    // 每页显示条数
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      console.log(this.pageSize); // 每页下拉显示数据
    },
    // 当前页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage);
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else if (rowIndex % 3 == 0) {
        return "info-row";
      }
      return "success-row";
    },
  },
  created() {
    let that = this;
    // axios.get('http://localhost:8181/fruit/list').then(function (response) {
    //   _this.tableData = response.data;
    // })
    /*GetTableAPI().then(function (response) {
      that.tableData = response.data;
      that.pageSize = 5;
      that.currentPage = 1;
    });*/
  },
  data() {
    return {
      //tableData: [],
      tableData: [
        {
          "id": 86,
          "fruitName": "2022-04-02",
          "sale": 6051,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0013.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 89,
          "fruitName": "2022-04-05",
          "sale": 13086,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0016.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 91,
          "fruitName": "2022-04-07",
          "sale": 19660,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0018.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 92,
          "fruitName": "2022-04-08",
          "sale": 20398,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0019.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 93,
          "fruitName": "2022-04-09",
          "sale": 22609,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0020.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 94,
          "fruitName": "2022-04-10",
          "sale": 23937,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0021.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 95,
          "fruitName": "2022-04-11",
          "sale": 15173,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0022.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 96,
          "fruitName": "2022-04-12",
          "sale": 22348,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0023.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 97,
          "fruitName": "2022-04-13",
          "sale": 25141,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0024.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 98,
          "fruitName": "2022-04-14",
          "sale": 25146,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0025.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 99,
          "fruitName": "2022-04-15",
          "sale": 19872,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0026.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 100,
          "fruitName": "2022-04-16",
          "sale": 19923,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0027.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 101,
          "fruitName": "2022-04-17",
          "sale": 21582,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0028.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 102,
          "fruitName": "2022-04-18",
          "sale": 19831,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0029.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 103,
          "fruitName": "2022-04-19",
          "sale": 17332,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0030.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 104,
          "fruitName": "2022-04-20",
          "sale": 15861,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0031.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 105,
          "fruitName": "2022-04-21",
          "sale": 15861,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0032.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 106,
          "fruitName": "2022-04-22",
          "sale": 15698,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0033.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 107,
          "fruitName": "2022-04-23",
          "sale": 20634,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0034.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        },
        {
          "id": 108,
          "fruitName": "2022-04-24",
          "sale": 19657,
          "icon": "D:\\tmp\\images\\http://rbnl1agtv.hd-bkt.clouddn.com/vuefruit/0035.jpg",
          "createTime": null,
          "updateTime": "2022-04-25 08:18:20"
        }
      ],
      // 默认第1页
      currentPage: 1,
      // 默认显示5条
      pageSize: 6,
      // 默认总条数为0 这个无所谓，前端分页可以计算数组的length
      totalSize: 0,
    };
  },
};
</script>

<style scoped>
#main {
  width: 55%;
  height: 600px;
  text-align: center;
  margin: 0 auto;
}
</style>
