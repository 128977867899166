<template>
  <div id="main">
    <div v-show="loading" :style="{backgroundImage:'url(${img})'}">
      正在查询中，请稍后。。。。
<!--      <img :src="src/assets/075.gif">-->
      <img :src="defaultImg" />
    </div>

    <div>
      <el-row :gutter="32"  class="el-row" type="flex" >
        <el-col :span="6" v-for = "(item,index) in musicList" :key="item.id" class="el-col" >
          <div style="border: 1px solid red" @click="playMusic(item)">
            <el-card class="el-card"  :key="index"  >
              <div slot="header" class="clearfix">
                <span>{{item.title}}</span>
              </div>
              <div >

                <div class="text item">
                  {{item.geDanHref}}
                </div>
                <div class="text item">
                  <img :src="item.imgSrc" :alt="item.imgAlt" :title="item.imgAlt">
                </div>

              </div>
            </el-card>
          </div>
        </el-col>





      </el-row>
    </div>
    <el-divider>
      <div v-show="bottomMsgShow">
        <span >{{bottomMsg}}</span>
      </div>

    </el-divider>

  </div>

</template>

<script>
import {getGeDan} from '../request/api.js'
import deImg  from '@/assets/075.gif'
export default {
  methods: {
    playMusic: function (param){
      console.log(param)
      this.$router.push({
        path: '/musicAudio',
        name: 'musicAudio',
        params: {
          param: param
        }

      })
    }

  },
  // 钩子函数
  created: function () {
    // 隐藏脚部模版代码
    this.$emit('footer', false);

    const that = this;
    that.loading=true;
    this.bottomMsgShow=false;
    getGeDan().then(function(response){
      that.musicList = response.data;
      that.loading = false;
      that.bottomMsgShow=true;
    })

  },
  data() {
    return {
      musicList: [],
      loading:false,
      defaultImg:require('@/assets/075.gif'),
      bottomMsgShow:false,
      bottomMsg:'我是有底线的 >!< ',
    };
  },
};
</script>

<style type="text/css">
#main{
  margin-top: -20px;
  width: 80%;
  height: 600px;
  text-align: center;
  margin:0 auto;
}


.item {
  margin-bottom: 10px;
}
.item_tag{
  float:left;
  text-align:left;
}
.item_desr{
  margin-left: 60px;
  min-height: 40px;
  text-align:left;
}
.text {
  width: 100%;
  font-size: 12px;
  font-family: "Microsoft YaHei";
  color: #909399;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.el-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
  /*transition: all .5s;*/
}
.el-card:hover{
  margin-top: -5px;
}
.el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap
}
.el-col {
  border-radius: 4px;
  align-items: stretch;
  margin-bottom: 40px;
}


</style>