<template>
  <div id="main">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-input
            v-model="musicName"
            style="width: 200px"
            placeholder="请输入要搜索的歌曲名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search" v-model="musicName"
        >查询
        </el-button
        >
        <el-button type="primary" @click="getRand">随机歌曲</el-button>
        <el-button
            type="primary"
            v-show="randTableData != '' && tableData.length === 0"
            @click="playMusic2"
        >播放
        </el-button
        >
      </el-form-item>

      <audio id="audio2" :paused="audioPaused" :src="playUrl" controls loop
             ref="audioVo"
      ></audio>


    </el-form>

    <!-- 热门搜索歌曲名称 -->
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="热门搜索">
        <el-tag
            v-for="item in hotSearch"
            @click="setHotToSearchName(item.first)"
        >{{ item.first }}
        </el-tag
        >
      </el-form-item>
    </el-form>

    <div></div>

    <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
        border
        style="width: 100%"
    >
      <el-table-column prop="audioName" label="音乐名称" width="300">
      </el-table-column>
      <!-- <el-table-column prop="playUrl" label="音乐播放地址" width="450">
      </el-table-column> -->

      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="playMusic(scope.row)" type="text" size="small"
          >播放
          </el-button
          >
<!--          <el-button type="text" size="small" @click="pauseMusic(scope.row)">暂停</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="currentPage"
          :page-sizes="[5,8,10,15,20,30,40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
      ></el-pagination>

    </div>

    <el-form
        v-show="randTableData != '' && tableData.length === 0"
        v-model="randTableData"
        class="demo-form-inline"
    >
      <el-form-item label="歌手名称">
        <el-input v-model="randTableData.artistsname" placeholder=""></el-input>
      </el-form-item>

      <el-form-item label="歌曲名称">
        <el-input v-model="randTableData.name"></el-input>
      </el-form-item>

      <el-form-item label="歌手名称">
        <img :src="randTableData.picurl" style="width:100px;height: 100px"/>
      </el-form-item>

      <!--      <el-form-item label="歌曲地址">-->
      <!--        <el-input v-model="randTableData.url"></el-input>-->
      <!--      </el-form-item>-->
    </el-form>
  </div>
</template>

<script>
import {queryMusicByNameAPI} from "../request/api";

export default {
  methods: {
    setHotToSearchName: function (item) {
      this.musicName = item;
      this.search();
    },
    getRand: function () {
      this.tableData = [];
      const that = this;
      axios
          .get("https://api.uomg.com/api/rand.music?sort=热歌榜&format=json")
          .then(function (response) {
            that.randTableData = response.data.data;
          });
    },
    playMusic2: function () {
      this.playUrl = this.randTableData.url;
      // 自动播放
      this.$refs.audioVo.autoplay = true;
      debugger
      //控制音量
      this.$refs.audioVo.volume = 0.4;
      setInterval(function () {
        console.log(this);

      }, 1000);
    },
    search: function () {
      const musicName = this.musicName;
      if ("" == musicName) {
        this.$message("歌曲名称不可以为空哦!");
        return;
      }
      this.loading = true;
      const that = this;
      // axios.get("http://localhost:8181/Test?musicName=" + this.musicName)
      //     .then(function (response) {
      //       that.tableData = response.data;
      //       that.loading = false;
      //     });

      queryMusicByNameAPI(this.musicName)
          .then(function (response) {
            that.tableData = response.data;
            that.loading = false;
          });


    },

    playMusic: function (res) {
      this.playUrl = res.playUrl;
      // 自动播放
      this.$refs.audioVo.autoplay = true;
      //控制音量
      this.$refs.audioVo.volume = 0.4;
    },
    // 暂停
    pauseMusic: function (res) {
      //this.$refs.audioVo.autoplay = false;
      debugger
      this.$refs.audio.autoplay = false;
    },
    // 每页显示条数
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      console.log(this.pageSize) // 每页下拉显示数据
    },
    // 当前页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      console.log(this.currentPage)
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      } else if (rowIndex % 3 == 0) {
        return 'info-row';
      }
      return 'success-row';
    },
  },
  // 钩子函数
  created: function () {
    const that = this;
    axios.get("https://autumnfish.cn/search/hot").then(function (response) {
      that.hotSearch = response.data.result.hots;
    });
  },
  data() {
    return {
      tableData: [],
      // 默认第1页
      currentPage: 1,
      // 默认显示5条
      pageSize: 6,
      // 默认总条数为0 这个无所谓，前端分页可以计算数组的length
      totalSize: 0,
      randTableData: [],
      musicName: "",
      playUrl: "",
      hotSearch: [],
      loading: false,
      audioPaused: false //是否暂停
    };
  },
};
</script>

<style scoped>
#main {
  margin-top: -20px;
  width: 52%;
  height: 600px;
  text-align: center;
  margin: 0 auto;
}

</style>