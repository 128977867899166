<template>
    <div class="main">
        <!-- 首页轮播图 -->
        <div class="carousel">
            <el-carousel height="15vw">
                <el-carousel-item v-for="item in imgList" :key="item.id">
                    <img :src="item.path" :alt="item.name" style="width: 100%; height: auto"/>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="two">
            <!-- 首页日历 -->
            <div class="main_today">
               <!-- <el-calendar v-model="timeValue" id="calendar">
                    &lt;!&ndash; 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法&ndash;&gt;
                    <template
                            slot="dateCell"
                            slot-scope="{date, data}">
                        <div>
                            {{ data.day.split('-').slice(1).join('-') }}

                        </div>
                        <div style="line-height: 35px">

                            <p style="font-size: 1px;color:orangered" >{{dealMyDate(data.day)}}</p>
                        </div>

                    </template>
                </el-calendar>-->

                <el-calendar v-model="timeValue" id="calendar">
                    <template slot="dateCell" slot-scope="{date, data}">
                        <!--自定义内容-->
                        <div @click="changeTime(date,data)">
                            <div class="calendar-day">{{ data.day.split('-').slice(2).join('-') }}</div>
                            <div v-for="item in calendarData">
                                <div v-if="(item.months).indexOf(data.day.split('-').slice(1)[0])!=-1">
                                    <div v-if="(item.days).indexOf(data.day.split('-').slice(2).join('-'))!=-1">
                                        <div class="is-selected">{{item.content}}</div>
                                    </div>
                                    <div v-else></div>
                                </div>
                                <div v-else></div>
                            </div>
                        </div>
                    </template>
                </el-calendar>
            </div>

        </div>
        <div id="resou">
            <div class="bulletin">
                百度热搜前10条
                <br/>
                <el-carousel class="lunbo" height="35" direction="vertical">
                    <!--<el-carousel-item v-for="(item, index) in currentTabList" :key="index">
                        <a :href="'/resource?id=' + item.id + '&type=' + currentTab" target="_blank">{{ item.title }}</a>
                    </el-carousel-item>-->
                    <el-carousel-item v-for="(item, index) in baiduHotLimitList" :key="index">
                        <a :href="item.hotDetailHref" target="_blank">{{ item.title }}</a>
                    </el-carousel-item>

                </el-carousel>

            </div>
            <div >
                微博热搜前10条
                <br/>
                <el-carousel class="lunbo" height="35" direction="vertical">
                    <!--<el-carousel-item v-for="(item, index) in currentTabList" :key="index">
                        <a :href="'/resource?id=' + item.id + '&type=' + currentTab" target="_blank">{{ item.title }}</a>
                    </el-carousel-item>-->
                    <el-carousel-item v-for="(item, index) in weiboHotLimitList" :key="index">
                        <a :href="item.hotDetailHref" target="_blank">{{ item.title }}</a>
                    </el-carousel-item>

                </el-carousel>
            </div>
        </div>

        <hr/>
        <!-- 首页资源 -->
        <div class="mySources">
            学习资源,左侧是资源类型菜单,右侧是具体的内容,使用 块状展示
        </div>
    </div>
</template>

<script>
    import {getCarousel, getBaiduHotNewsByTotal, getWeiboHotNewsByTotal} from "../request/api";

    export default {
        name: "HelloWorld",
        data() {
            return {
                timeValue: new Date(),
                imgList: [{
                    "id": 1,
                    "name": "加油01",
                    "path": "https://bpic.588ku.com/back_pic/05/81/90/645c3d815064d81.jpg",
                    "imageSort": 1,
                    "createTime": "2022-04-19 16:45:32",
                    "updateTime": "2022-04-19 16:45:32",
                    "delFlag": "0"
                },
                    {
                        "id": 2,
                        "name": "加油02",
                        "path": "https://img.zcool.cn/community/01b0785fdb06d011013ee04dcff4fe.jpg@1280w_1l_2o_100sh.jpg",
                        "imageSort": 2,
                        "createTime": "2022-04-19 16:51:14",
                        "updateTime": "2022-04-19 16:51:14",
                        "delFlag": "0"
                    },
                    {
                        "id": 3,
                        "name": "加油03",
                        "path": "https://ts1.cn.mm.bing.net/th/id/R-C.563b4153627019bf3eca5f9b9a8947a4?rik=eBzMDWr54W5C6Q&riu=http%3a%2f%2fpic.616pic.com%2fys_bnew_img%2f00%2f36%2f86%2fZZ3QS9nyYy.jpg&ehk=AkCS6nL66HEzQQhR9%2fDAf0yFTwNNpeBtmPK3CQaKyLY%3d&risl=&pid=ImgRaw&r=0&sres=1&sresct=1",
                        "imageSort": 3,
                        "createTime": "2022-04-19 17:08:39",
                        "updateTime": "2022-04-19 17:08:39",
                        "delFlag": "0"
                    }],
                hotNewsTotal: 20,
                baiduHotLimitList: [{
                    "imgHref": "https://fyb-1.cdn.bcebos.com/fyb/de6163834f53ca92c1273fff98ac9078.jpeg?x-bce-process=image/resize,m_fill,w_256,h_170",
                    "title": "机器人概念股震荡下挫",
                    "hotDesc": "查看更多>",
                    "hotDetailHref": "https://www.baidu.com/s?wd=%E6%9C%BA%E5%99%A8%E4%BA%BA%E6%A6%82%E5%BF%B5%E8%82%A1%E9%9C%87%E8%8D%A1%E4%B8%8B%E6%8C%AB&sa=fyb_news&rsv_dl=fyb_news",
                    "hotType": "",
                    "indexCode": "134598",
                    "indexCodeName": "热搜指数",
                    "dateTime": "2024-08-22 19:36:43"
                },
                    {
                        "imgHref": "https://fyb-1.cdn.bcebos.com/fyb/de6163834f53ca92c1273fff98ac9078.jpeg?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "中央气象台继续发布高温黄色预警",
                        "hotDesc": "查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E4%B8%AD%E5%A4%AE%E6%B0%94%E8%B1%A1%E5%8F%B0%E7%BB%A7%E7%BB%AD%E5%8F%91%E5%B8%83%E9%AB%98%E6%B8%A9%E9%BB%84%E8%89%B2%E9%A2%84%E8%AD%A6&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "2324951",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:34:24"
                    },
                    {
                        "imgHref": "https://fyb-1.cdn.bcebos.com/fyb/de6163834f53ca92c1273fff98ac9078.jpeg?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "美国“林肯”号航母抵达中东",
                        "hotDesc": "查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E7%BE%8E%E5%9B%BD%E2%80%9C%E6%9E%97%E8%82%AF%E2%80%9D%E5%8F%B7%E8%88%AA%E6%AF%8D%E6%8A%B5%E8%BE%BE%E4%B8%AD%E4%B8%9C&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "100000",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:34:24"
                    },
                    {
                        "imgHref": "https://fyb-1.cdn.bcebos.com/fyb/de6163834f53ca92c1273fff98ac9078.jpeg?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "失孤原型案受害者称呼富吉是人间恶魔",
                        "hotDesc": "查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E5%A4%B1%E5%AD%A4%E5%8E%9F%E5%9E%8B%E6%A1%88%E5%8F%97%E5%AE%B3%E8%80%85%E7%A7%B0%E5%91%BC%E5%AF%8C%E5%90%89%E6%98%AF%E4%BA%BA%E9%97%B4%E6%81%B6%E9%AD%94&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "100000",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:32:32"
                    },
                    {
                        "imgHref": "//fyb-pc-static.cdn.bcebos.com/static/asset/whitet@2x_0fd85d7c9f42d73571bd1168903afb74.png",
                        "title": "习近平点赞奥运健儿赤子情怀",
                        "hotDesc": "“祖国和人民为你们骄傲，为你们点赞。”8月20日，习近平总书记在人民大会堂接见巴黎奥运会中国体育代表团全体成员，并发表重... 查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E4%B9%A0%E8%BF%91%E5%B9%B3%E7%82%B9%E8%B5%9E%E5%A5%A5%E8%BF%90%E5%81%A5%E5%84%BF%E8%B5%A4%E5%AD%90%E6%83%85%E6%80%80&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "4960030",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-2.cdn.bcebos.com/hotboard_image/5047a3b05a4bf85a6116626ba98d415c?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "花果山景区：通关黑神话可免费游玩",
                        "hotDesc": "据连云港花果山风景区消息，今年8月22日至12月31日，所有通关《黑神话：悟空》的玩家可免费游玩花果山，引发热议。 查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E8%8A%B1%E6%9E%9C%E5%B1%B1%E6%99%AF%E5%8C%BA%EF%BC%9A%E9%80%9A%E5%85%B3%E9%BB%91%E7%A5%9E%E8%AF%9D%E5%8F%AF%E5%85%8D%E8%B4%B9%E6%B8%B8%E7%8E%A9&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "热",
                        "indexCode": "4941148",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-2.cdn.bcebos.com/hotboard_image/4dcf3b62ac6187aabeac407c7a7b0839?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "霍震霆回应郭晶晶担任跳水裁判长",
                        "hotDesc": "近日，霍震霆在接受采访时提到儿媳郭晶晶一脸骄傲，并回应郭晶晶担任奥运会跳水裁判长：晶晶做裁判长好认真，忙到没时间聚餐。 查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E9%9C%8D%E9%9C%87%E9%9C%86%E5%9B%9E%E5%BA%94%E9%83%AD%E6%99%B6%E6%99%B6%E6%8B%85%E4%BB%BB%E8%B7%B3%E6%B0%B4%E8%A3%81%E5%88%A4%E9%95%BF&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "热",
                        "indexCode": "4869075",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-2.cdn.bcebos.com/hotboard_image/a7f2b643ea76961934e10654edbb6487?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "今日处暑，暑去凉来",
                        "hotDesc": "“离离暑云散，袅袅凉风起。”处暑至，暑已尽，凉秋来。夏末的余温与初秋的凉意交织，带来热烈后的宁静。 查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E4%BB%8A%E6%97%A5%E5%A4%84%E6%9A%91%EF%BC%8C%E6%9A%91%E5%8E%BB%E5%87%89%E6%9D%A5&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "4776024",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-2.cdn.bcebos.com/hotboard_image/d6bd55dfc4ba180d46e53f688577a68d?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "男子称献血8次被告知不能优先用血",
                        "hotDesc": "8月19日，河北廊坊一男子称自己献血8次，却因献血证过期，被医院告知不能优先用血，引发关注。 查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E7%94%B7%E5%AD%90%E7%A7%B0%E7%8C%AE%E8%A1%808%E6%AC%A1%E8%A2%AB%E5%91%8A%E7%9F%A5%E4%B8%8D%E8%83%BD%E4%BC%98%E5%85%88%E7%94%A8%E8%A1%80&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "4658546",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-1.cdn.bcebos.com/fyb/de6163834f53ca92c1273fff98ac9078.jpeg?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "不许带外卖上楼 女子在闸机口吃饭",
                        "hotDesc": "查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E4%B8%8D%E8%AE%B8%E5%B8%A6%E5%A4%96%E5%8D%96%E4%B8%8A%E6%A5%BC+%E5%A5%B3%E5%AD%90%E5%9C%A8%E9%97%B8%E6%9C%BA%E5%8F%A3%E5%90%83%E9%A5%AD&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "4534373",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-1.cdn.bcebos.com/fyb/444846eeb217ede8c838d13ae8c9c9b0.jpg?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "#为何印度发生这么多强奸案#",
                        "hotDesc": "查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%23%E4%B8%BA%E4%BD%95%E5%8D%B0%E5%BA%A6%E5%8F%91%E7%94%9F%E8%BF%99%E4%B9%88%E5%A4%9A%E5%BC%BA%E5%A5%B8%E6%A1%88%23&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "4481836",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-2.cdn.bcebos.com/hotboard_image/e3a6d59c08b2e86290a8608d236dbc26?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "潘展乐 先把你们应付过去",
                        "hotDesc": "22日，潘展乐闪现杭州，被记者问接下来有何计划时，潘展乐打趣回应：先把你们应付过去，然后好好放松再训练。 查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E6%BD%98%E5%B1%95%E4%B9%90+%E5%85%88%E6%8A%8A%E4%BD%A0%E4%BB%AC%E5%BA%94%E4%BB%98%E8%BF%87%E5%8E%BB&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "4383368",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-2.cdn.bcebos.com/hotboard_image/76614f1d7fd7ba5bfd59065d6e245ee8?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "凉山多地出现人贩子？假的",
                        "hotDesc": "近日，网传四川凉山多地出现“不法分子”和“人贩子”，引起关注。对此，当地网信、公安回应：经调查核实，相关信息均为不实信息... 查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E5%87%89%E5%B1%B1%E5%A4%9A%E5%9C%B0%E5%87%BA%E7%8E%B0%E4%BA%BA%E8%B4%A9%E5%AD%90%EF%BC%9F%E5%81%87%E7%9A%84&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "4269257",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-2.cdn.bcebos.com/hotboard_image/43b167337fa41b7f8610b13033d91a8d?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "原来关晓彤是林黛玉最初人选",
                        "hotDesc": "近日，在媒体直播活动中电影《红楼梦之金玉良缘》的导演胡玫透露，关晓彤原本是林黛玉一角的最初人选。 查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E5%8E%9F%E6%9D%A5%E5%85%B3%E6%99%93%E5%BD%A4%E6%98%AF%E6%9E%97%E9%BB%9B%E7%8E%89%E6%9C%80%E5%88%9D%E4%BA%BA%E9%80%89&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "4199991",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-1.cdn.bcebos.com/fyb/de6163834f53ca92c1273fff98ac9078.jpeg?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "别再捧杀《黑神话：悟空》了",
                        "hotDesc": "查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E5%88%AB%E5%86%8D%E6%8D%A7%E6%9D%80%E3%80%8A%E9%BB%91%E7%A5%9E%E8%AF%9D%EF%BC%9A%E6%82%9F%E7%A9%BA%E3%80%8B%E4%BA%86&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "4022660",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-2.cdn.bcebos.com/hotboard_image/26ff5dc4bc340ed3fcb502c87c759377?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "小孩骑行被碾身亡案司机被移送检方",
                        "hotDesc": "22日，记者了解到此前备受关注的“河北小孩骑行摔倒被小车碾压致死”案件，司机和小孩家属双方间未达成协商，已移送检察机关。 查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E5%B0%8F%E5%AD%A9%E9%AA%91%E8%A1%8C%E8%A2%AB%E7%A2%BE%E8%BA%AB%E4%BA%A1%E6%A1%88%E5%8F%B8%E6%9C%BA%E8%A2%AB%E7%A7%BB%E9%80%81%E6%A3%80%E6%96%B9&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "3964415",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-1.cdn.bcebos.com/fyb/de6163834f53ca92c1273fff98ac9078.jpeg?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "41名被清退事业编人员薪资已结算",
                        "hotDesc": "查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=41%E5%90%8D%E8%A2%AB%E6%B8%85%E9%80%80%E4%BA%8B%E4%B8%9A%E7%BC%96%E4%BA%BA%E5%91%98%E8%96%AA%E8%B5%84%E5%B7%B2%E7%BB%93%E7%AE%97&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "3868423",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-1.cdn.bcebos.com/fyb/de6163834f53ca92c1273fff98ac9078.jpeg?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "48小时买27套 异地购房团重出江湖",
                        "hotDesc": "查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=48%E5%B0%8F%E6%97%B6%E4%B9%B027%E5%A5%97+%E5%BC%82%E5%9C%B0%E8%B4%AD%E6%88%BF%E5%9B%A2%E9%87%8D%E5%87%BA%E6%B1%9F%E6%B9%96&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "3753093",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-1.cdn.bcebos.com/fyb/de6163834f53ca92c1273fff98ac9078.jpeg?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "大爷肇事逃逸后侧翻身亡",
                        "hotDesc": "查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E5%A4%A7%E7%88%B7%E8%82%87%E4%BA%8B%E9%80%83%E9%80%B8%E5%90%8E%E4%BE%A7%E7%BF%BB%E8%BA%AB%E4%BA%A1&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "3674002",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    },
                    {
                        "imgHref": "https://fyb-2.cdn.bcebos.com/hotboard_image/c107f98cbc7dba8fff57e6c9d94d7310?x-bce-process=image/resize,m_fill,w_256,h_170",
                        "title": "四方馆檀健次打工人精神状态",
                        "hotDesc": "8月22日，在《四方馆》线下活动现场，“i中之i”檀健次为工作变i做e，打工状态演我本人了！ 查看更多>",
                        "hotDetailHref": "https://www.baidu.com/s?wd=%E5%9B%9B%E6%96%B9%E9%A6%86%E6%AA%80%E5%81%A5%E6%AC%A1%E6%89%93%E5%B7%A5%E4%BA%BA%E7%B2%BE%E7%A5%9E%E7%8A%B6%E6%80%81&sa=fyb_news&rsv_dl=fyb_news",
                        "hotType": "",
                        "indexCode": "3519912",
                        "indexCodeName": "热搜指数",
                        "dateTime": "2024-08-22 19:29:26"
                    }],
                weiboHotLimitList: [{
                    "imgHref": "",
                    "title": "茉莉奶白宝藏茶",
                    "hotDesc": "",
                    "hotDetailHref": "https://s.weibo.com/weibo?q=茉莉奶白宝藏茶",
                    "hotType": null,
                    "indexCode": "715868",
                    "indexCodeName": "上新",
                    "dateTime": "2024-08-22 19:40:29"
                },
                    {
                        "imgHref": "",
                        "title": "眼镜控就爱看这个",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=眼镜控就爱看这个",
                        "hotType": "动漫",
                        "indexCode": "120374",
                        "indexCodeName": "新",
                        "dateTime": "2024-08-22 19:40:02"
                    },
                    {
                        "imgHref": "",
                        "title": "日本华裔乒乓名将涉嫌猥亵被捕",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=日本华裔乒乓名将涉嫌猥亵被捕",
                        "hotType": "体育",
                        "indexCode": "133275",
                        "indexCodeName": null,
                        "dateTime": "2024-08-22 19:36:43"
                    },
                    {
                        "imgHref": "",
                        "title": "老外给黑神话玩家写200页西游科普",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=老外给黑神话玩家写200页西游科普",
                        "hotType": "游戏",
                        "indexCode": "133048",
                        "indexCodeName": "新",
                        "dateTime": "2024-08-22 19:36:43"
                    },
                    {
                        "imgHref": "",
                        "title": "买星纪元ES不就图一个稳吗",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=买星纪元ES不就图一个稳吗",
                        "hotType": null,
                        "indexCode": "1044764",
                        "indexCodeName": "焕新",
                        "dateTime": "2024-08-22 19:35:31"
                    },
                    {
                        "imgHref": "",
                        "title": "智能生活家",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=智能生活家",
                        "hotType": null,
                        "indexCode": "635502",
                        "indexCodeName": "荐",
                        "dateTime": "2024-08-22 19:35:26"
                    },
                    {
                        "imgHref": "",
                        "title": "朱苏last night",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=朱苏last night",
                        "hotType": "演出",
                        "indexCode": "200726",
                        "indexCodeName": "新",
                        "dateTime": "2024-08-22 19:32:38"
                    },
                    {
                        "imgHref": "",
                        "title": "周扬青看情敌不如看热闹",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=周扬青看情敌不如看热闹",
                        "hotType": "艺人",
                        "indexCode": "147972",
                        "indexCodeName": "新",
                        "dateTime": "2024-08-22 19:32:38"
                    },
                    {
                        "imgHref": "",
                        "title": "29.98万起 魏牌全新蓝山上市",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=29.98万起 魏牌全新蓝山上市",
                        "hotType": null,
                        "indexCode": "603634",
                        "indexCodeName": "上新",
                        "dateTime": "2024-08-22 19:31:28"
                    },
                    {
                        "imgHref": "",
                        "title": "律师说法",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=律师说法",
                        "hotType": null,
                        "indexCode": "604147",
                        "indexCodeName": "荐",
                        "dateTime": "2024-08-22 19:30:55"
                    },
                    {
                        "imgHref": "",
                        "title": "决战CPA",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=决战CPA",
                        "hotType": null,
                        "indexCode": "603215",
                        "indexCodeName": "荐",
                        "dateTime": "2024-08-22 19:30:48"
                    },
                    {
                        "imgHref": "",
                        "title": "霍震霆回应郭晶晶担任跳水裁判长",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=霍震霆回应郭晶晶担任跳水裁判长",
                        "hotType": "体育",
                        "indexCode": "1418717",
                        "indexCodeName": "新",
                        "dateTime": "2024-08-22 19:29:31"
                    },
                    {
                        "imgHref": "",
                        "title": "女子吃完路边捡来死狗全身出血",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=女子吃完路边捡来死狗全身出血",
                        "hotType": "社会新闻",
                        "indexCode": "1384336",
                        "indexCodeName": "热",
                        "dateTime": "2024-08-22 19:29:31"
                    },
                    {
                        "imgHref": "",
                        "title": "2024已开启渐变色模式",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=2024已开启渐变色模式",
                        "hotType": "社会新闻",
                        "indexCode": "1266256",
                        "indexCodeName": null,
                        "dateTime": "2024-08-22 19:29:31"
                    },
                    {
                        "imgHref": "",
                        "title": "小孩骑行被碾压身亡肇事司机移送检方",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=小孩骑行被碾压身亡肇事司机移送检方",
                        "hotType": "社会新闻",
                        "indexCode": "1266198",
                        "indexCodeName": "新",
                        "dateTime": "2024-08-22 19:29:31"
                    },
                    {
                        "imgHref": "",
                        "title": "新版黛玉临终",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=新版黛玉临终",
                        "hotType": "电影",
                        "indexCode": "1074938",
                        "indexCodeName": "新",
                        "dateTime": "2024-08-22 19:29:31"
                    },
                    {
                        "imgHref": "",
                        "title": "王楚钦 下个周期更专注单打",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=王楚钦 下个周期更专注单打",
                        "hotType": "体育",
                        "indexCode": "629077",
                        "indexCodeName": "新",
                        "dateTime": "2024-08-22 19:29:31"
                    },
                    {
                        "imgHref": "",
                        "title": "黑神话第一批受害者出现了",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=黑神话第一批受害者出现了",
                        "hotType": "游戏",
                        "indexCode": "605798",
                        "indexCodeName": "热",
                        "dateTime": "2024-08-22 19:29:31"
                    },
                    {
                        "imgHref": "",
                        "title": "老人急病离世藏了15万的床垫被扔了",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=老人急病离世藏了15万的床垫被扔了",
                        "hotType": "社会新闻",
                        "indexCode": "593299",
                        "indexCodeName": null,
                        "dateTime": "2024-08-22 19:29:31"
                    },
                    {
                        "imgHref": "",
                        "title": "蔡少芬 香港第一个公开结婚的演员",
                        "hotDesc": "",
                        "hotDetailHref": "https://s.weibo.com/weibo?q=蔡少芬 香港第一个公开结婚的演员",
                        "hotType": "综艺",
                        "indexCode": "540924",
                        "indexCodeName": "新",
                        "dateTime": "2024-08-22 19:29:31"
                    }],
                resDate: [
                    {"date":"2024-08-02","content":"放假"},
                    {"date":"2024-08-03","content":"放假1"},
                    {"date":"2024-08-03","content":"放假2"},
                    {"date":"2024-08-03","content":"放假3"},
                    {"date":"2024-08-03","content":"放假4"},
                    {"date":"2024-08-26","content":"去交电费"},
                    {"date":"2024-08-12","content":"去学习vue"}
                ]

            };
        },
        props: {
            msg: String,
        },
        methods: {
            //处理时间
            handleTime(s) {
                return s < 10 ? '0' + s : s
            },
            //点击天数
            changeTime(date, data) {
                console.log(date, data);
            },
            dealMyDate(v) {
                debugger
                console.log(v)
                let len = this.resDate.length
                let res = ""
                for(let i=0; i<len; i++){
                    if(this.resDate[i].date == v) {
                        res = this.resDate[i].content
                        break
                    }
                }
                return res
            }
        },
        created: function () {
            let that = this;
            // 获取轮播图片
            /*getCarousel().then(function (response) {
                that.imgList = response.data.data;
            });*/

            let hotNewsTotal = this.hotNewsTotal;
            console.info(hotNewsTotal)
            // 获取20条百度热搜数据
            /*getBaiduHotNewsByTotal(this.hotNewsTotal).then(function (response) {
                debugger
                that.baiduHotLimitList = response.data.data;
            });*/

            // 获取20条微博热搜数据
          /*  getWeiboHotNewsByTotal(this.hotNewsTotal).then(function (response) {
                debugger
                that.weiboHotLimitList = response.data.data;
            });*/

            this.$nextTick(() => {
                // 点击前一个月
                let prevBtn = document.querySelector(
                    ".el-calendar__button-group .el-button-group>button:nth-child(1)"
                );
                prevBtn.addEventListener("click", e => {
                    let d = new Date(this.timeValue);
                    let resDate = d.getFullYear() + '-' + this.handleTime((d.getMonth() + 1));//2020-08
                })
                //点击下一个月
                let nextBtn = document.querySelector(
                    ".el-calendar__button-group .el-button-group>button:nth-child(3)"
                );
                nextBtn.addEventListener("click", e => {
                    let d = new Date(this.timeValue);
                    let resDate = d.getFullYear() + '-' + this.handleTime((d.getMonth() + 1));//2020-10
                })
                //点击今天
                let todayBtn = document.querySelector(
                    ".el-calendar__button-group .el-button-group>button:nth-child(2)"
                );
                todayBtn.addEventListener("click", e => {
                    let d = new Date(this.timeValue);
                    let resDate = d.getFullYear() + '-' + this.handleTime((d.getMonth() + 1));//2020-09
                })

            });


        },
        mounted() {
            this.calendarData.push({
                months: [9,10],//当前月
                days:[1,2,3],//天
                content:'自定义要展示的内容'
            })
        }
    }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .main {
        height: 1000px;
    }
    .div-Calendar {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .calendar-day {
        text-align: center;
        color: #202535;
        line-height: 30px;
        font-size: 12px;
    }

    .is-selected {
        color: #F8A535;
        font-size: 10px;
        margin-top: 5px;
    }

    #calendar .el-button-group>.el-button:not(:first-child):not(:last-child):after {
        content: '当月';
    }

    /*修改日历大小 */
    .main_today /deep/ .el-calendar-table .el-calendar-day {
        font-size: 1px;
        height: 60px;
    }

    .calendar-day {
        text-align: center;
        color: #202535;
        line-height: 30px;
        font-size: 12px;
    }

    .is-selected {
        color: red;
        font-size: 10px;
        margin-top: 5px;
    }

    #calendar .el-button-group > .el-button:not(:first-child):not(:last-child):after {
        content: '当月';
    }


    .main_today {
        float: left;
        width: 100%;
        height: 138px;
        /* height: 40px; */
        /* display: flex; */
        border: 1px solid red;
        /* position: relative;
        float: left; */
        margin-top: 1pc;
    }

    /* .two{
        border: 1px solid green;
        width: 100%;
        height: 500px;
    } */
    .two {
        border: 1px solid green;
        height: 500px;
    }


   /* .bulletin {
        float: left;
        margin-top: 16px;
        margin-left: 16px;
        border: 1px solid blue;
        width: 400px;
        height: 338px;
    }*/

    .lunbo {
        padding: 0 8px 0 0;
        box-sizing: border-box;
        height: 100px;
        line-height: 100px;
        text-overflow: ellipsis;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
    }


    /* 轮播图样式 */
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
</style>
