<template>
  <div id="main">
    <el-form
        ref="fruitRules"
        :model="fruit"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
        style="width: 600px"
    >
      <el-form-item label="名称" prop="fruitName">
        <el-input v-model="fruit.fruitName"></el-input>
      </el-form-item>
      <el-form-item label="销量" prop="sale">
        <el-input v-model.number="fruit.sale"></el-input>
      </el-form-item>
      <!--    <el-form-item label="图片" prop="icon">-->
      <!--      <el-input v-model="fruit.icon"></el-input>-->
      <!--    </el-form-item>-->

      <el-form-item label="图片" prop="icon2">
<!--        <el-upload class="upload-demo"-->
<!--                   action="http://150.158.173.77:8181/fruitAdmin/api/upload"-->
<!--                   name="userHeaderPicture"-->
<!--                   v-model="fruit.icon"-->
<!--                   :on-preview="handlePreview"-->
<!--                   :on-remove="handleRemove"-->
<!--                   :on-success="handleAvatarSuccess"-->
<!--                   multiple-->
<!--        >-->
<!--          <el-button size="small" type="primary">点击上传</el-button>-->

<!--        </el-upload>-->

<!--        http://localhost/:8181/fruitAdmin/api/uploadToGitee-->
        <el-upload
            class="upload-demo"
            drag
            action="http://150.158.173.77:8181/fruitAdmin/api/upload"
            name="userHeaderPicture"
            v-model="fruit.icon"
            :on-success="handleAvatarSuccess"
            multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>

        <img :src="myImg" v-show="myImgShow" style="width:200px;height: 200px"/>
        <!--      <img :src="$host+'0002.png'"/>-->
      </el-form-item>


      <el-form-item>
        <el-button type="primary" @click="onSubmit('fruitRules')"
        >立即创建
        </el-button
        >
        <el-button @click="cancelSubmit">取消</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>
import {addFruitAPI} from '../request/api.js'

export default {
  name: "Add",
  data() {
    return {
      myImg:'',
      fruit: {
        fruitName: "",
        sale: "",
        icon: "",

      },
      rules: {
        fruitName: [
          {required: true, message: "请输入水果名称", trigger: "blur"},
        ],
        sale: [
          {required: true, message: "请输入销量", trigger: "blur"},
          {type: "number", message: "销量必须为数字值"},
        ],
        icon: [{required: true, message: "请输入图片链接", trigger: "blur"}],
      },
      myImgShow: false
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleAvatarSuccess(res, file) {
      debugger
      //this.imageUrl = URL.createObjectURL(file.raw);
      //重点  得到上传图片的名字
      this.fruit.icon = res.data.data;
      this.myImg = res.data.data;
      this.myImgShow = true;
    },

    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          debugger
          let _this = this;
          let fruit = this.fruit;
          addFruitAPI(this.fruit).then(function (response) {
            if (response.data) {
              debugger
              _this.$alert(_this.fruit.fruitName + "添加成功！", "添加数据", {
                confirmButtonText: "确定",
                callback: (action) => {
                  //跳转到/table
                  _this.$router.push("/table");
                },
              });
            }
          });

        }
      });
    },
    // 取消
    cancelSubmit:function(){
      this.$router.push("/table")
    }
  },
};
</script>

<style scoped>
#main{
  width: 40%;
  height: 600px;
  text-align: center;
  margin:0 auto;
}
</style>
