<template>
  <div id="app">
    <!--
    v-bind: 为元素绑定属性，比如 src,title,css
    -->
    <!--    完整方式： v-bind:src="" -->
    <img v-bind:src="imgSrc" alt="">
    <br>
    <!--    简写方式 :src

    :class="isActive?'active':'' "  表示是一个三元表达式，如果 isActive 为true，则值为active，
            否则就是一个空的字符串

    @Click: 表示绑定了一个函数，当点击的时候触发这个函数
    -->
    <img :src="imgSrc" alt="" :title="imgTitle+'Hahaha'"
         :class="isActive?'active':'' "
         @click="toggleIsShow">
    <br>
    <!--    :class="{active:isActive}" 这句话的意思是： 为img标签增加了一个class属性，值为active
     是否生效，取决于后面的isActive
     -->
    <img :src="imgSrc" alt="" :title="imgTitle+'Hahaha'"
         :class="{active:isActive}"
         @click="toggleIsShow">
    <br>

  </div>
</template>

<script>
export default {
  name: "VBind",
  data() {
    return {
      imgSrc: "http://www.itheima.com/images/logo.png",
      imgTitle: "黑马程序员",
      isActive: false
    }
  },
  methods: {
    toggleIsShow: function () {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style scoped>
.active {
  border: 1px solid red;
}
</style>